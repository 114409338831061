/* Services Page Styles */

.services-section {
  padding: 5em 0;
}

.services-section .services {
  padding: 6em 0 2em;
}

.services-section .services .service-item {
  margin: 0 auto;
  width: 85%;
  /* border-bottom: 1px solid var(--light-1); */
  border-radius: 0.7rem;
  padding: 5em 2em 5em 4em;
  transition: 0.2s ease-in-out all;
}

.services-section .services .service-item:last-child {
  border: none;
}

.services-section .services .service-item img {
  transition: transform 0.25s ease-in-out;
}

.services-section .services .service-item:hover img {
  transform: translateY(-20px);
}

.services-section .services .service-item:hover h3 {
  color: var(--green-1);
}

.services-section .services .service-item:hover {
  background-color: var(--light-3);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
}

.services-section .services .row {
  align-items: center;
}

.services-section .services img {
  width: 27em;
  max-width: 70%;
  padding-right: 2em;
}

.services-section .services h3 {
  font-size: 1.5em;
  color: var(--black);
}

.services-section .services p {
  color: var(--dark-3);
}

.services-section .tag-group li {
  padding-bottom: 1em;
}

/* SEO Section */

.seo-section-wrap {
  padding: 4rem 0 2rem;
}

.seo-section-wrap .seosec-header .sseo-head-img img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.seo-section-wrap .seosec-header h3 {
  font-family: var(--font-bold);
  font-size: calc(2rem + 8px);
  color: var(--green);
  margin: 0 0 1rem 0;
}

.seo-section-wrap .seosec-header h3 span {
  font-family: inherit;
  font-size: inherit;
  color: var(--blue);
}

.seo-section-wrap .seosec-header p {
  font-family: var(--font-regular);
  font-size: 1rem;
  max-width: 80%;
}

.seo-section-wrap button.sseo-btn {
  background: var(--green);
  padding: 0.5em 2em;
  border-radius: 4px;
  outline: none;
  border: none;
  color: var(--white);
  margin: 1em 0;
}

.seo-section-wrap .sseo-exclusive-serv-wrap h4 {
  font-family: var(--font-bold);
  font-size: 2rem;
  color: var(--black-30);
}

.seo-section-wrap .sseo-exc-item-wrap {
  margin: 2rem 0;
}

.seo-section-wrap .sseo-exc-item {
  padding: 4rem 0;
}

.seo-section-wrap .sseo-exc-item h5 {
  font-family: var(--font-bold);
  font-size: 1.5rem;
  color: var(--black);
  margin-bottom: 1rem;
}

.seo-section-wrap .sseo-exc-item p {
  font-family: var(--font-regular);
  font-size: 1rem;
  color: var(--black-42);
  line-height: 2;
}

/* Why Hire Us */

.sseo-why-hire-us {
  background: #edfff9;
  padding: 5rem 0 1rem;
  text-align: center;
}

.sseo-why-hire-us h3 {
  font-family: var(--font-bold);
  font-size: 2rem;
  color: var(--green);
  margin-bottom: 1.5rem;
}

.sseo-why-hire-us h3 span {
  font-family: inherit;
  font-size: inherit;
  color: var(--black-30);
}

.sseo-why-hire-us h6 {
  font-family: var(--font-regular);
  font-size: 1rem;
  color: var(--black-42);
  max-width: 85%;
  margin: 0 auto;
}

.sseo-why-hire-us .sseo-whu-item-wrap {
  margin: 3rem 0;
}

.sseo-why-hire-us .sseo-whu-item-wrap .sseo-whu-item {
  text-align: center;
  padding: 1rem 2rem;
}

.sseo-why-hire-us .sseo-whu-item-wrap .sseo-whu-item img {
  margin: 1rem 0 2rem;
}

.sseo-why-hire-us .sseo-whu-item-wrap .sseo-whu-item h4 {
  font-family: var(--font-bold);
  font-size: 1.5rem;
  color: var(--black);
  margin-bottom: 1rem;
}

.sseo-why-hire-us .sseo-whu-item-wrap .sseo-whu-item p {
  font-family: var(--font-regular);
  font-size: 0.9rem;
  color: var(--black-42);
}

/* End of Why Hire Us */

/* Service Aid */

.high-service-aid-wrap {
  padding: 4rem 0 2rem;
  text-align: center;
}

.high-service-aid-wrap .hsa-img {
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin: 2rem auto;
}

.high-service-aid-wrap .hsa-img img {
  max-width: 100%;
}

.high-service-aid-wrap h3 {
  font-family: var(--font-bold);
  font-size: 2.5rem;
  color: var(--black);
  margin: 3rem auto 1rem;
  max-width: 60%;
  line-height: 1.5;
}

.high-service-aid-wrap h3 span {
  font-family: inherit;
  color: var(--green);
}

.high-service-aid-wrap p {
  font-size: 1rem;
}

/* End of Service Aid */

/* SEO USP */

.seo-usp-wrap {
  padding: 3rem 0;
  text-align: center;
}

.seo-usp-wrap h3 {
  font-family: var(--font-bold);
  font-size: 2.5rem;
  max-width: 90%;
  margin: 2rem auto;
  line-height: 1.5;
}

.seo-usp-wrap h3 span {
  font-family: inherit;
  color: var(--green);
}

.seo-usp-wrap .seo-usp-points {
  padding: 3rem 0;
}

.seo-usp-wrap .seousp-img img {
  max-width: 100%;
}

.seo-usp-wrap .seousp-points-wrap {
  padding: 0;
}

.seo-usp-wrap .seousp-points-wrap .seousp-points-item {
  padding: 1rem 0;
  text-align: left;
}

.seo-usp-wrap .seousp-points-wrap .seousp-points-item img {
  margin: 0 0 1.5rem;
}

.seo-usp-wrap .seousp-points-wrap .seousp-points-item h4 {
  font-family: var(--font-bold);
  font-size: 1.5rem;
  color: var(--black);
}

.seo-usp-wrap .seousp-points-wrap .seousp-points-item p {
  font-size: 1rem;
  color: var(--black-42);
}

/* FAQ */

.seo-section-wrap .seo-faq-wrap {
  padding: 3rem 0;
}

.seo-section-wrap .seo-faq-wrap h3 {
  font-family: var(--font-bold);
  font-size: 2rem;
  color: var(--black-30);
  margin-bottom: 2rem;
}

.seo-section-wrap .seo-faq-wrap .seo-faq-item-wrap #seo_faq {
  padding-right: 1rem;
}

#seo_faq .accordion-item {
  border: none;
  padding: 0;
  border-radius: 0 !important;
}

#seo_faq .accordion-header button.accordion-button {
  font-family: var(--font-regular);
  font-size: 0.9rem;
  padding: 1rem 0;
  margin-bottom: 0;
  background: none;
  box-shadow: none;
  border-bottom: 1px solid var(--light-5);
  line-height: 1.5;
}

#seo_faq .accordion-header button.accordion-button:not(.collapsed) {
  font-family: var(--font-medium);
  color: var(--green);
  border: none;
}

#seo_faq .accordion-header button.accordion-button::after {
  display: none;
}

#seo_faq .accordion-body {
  padding: 0 0 1rem 0;
  border-bottom: 1px solid var(--green);
}

#seo_faq .accordion-body h6 {
  font-size: 0.9rem;
  color: var(--black);
  line-height: 1.7;
  margin: 0;
}

/* End of SEO USP */

/* End of SEO Section */

/* Media Queries */

@media only screen and (max-width: 768px) {
  .seo-section-wrap {
    padding: 10rem 0 0;
  }
  .seo-section-wrap img {
    max-width: 100%;
  }
  .services-section .services {
    padding: 1em 0;
  }
  .services-section .services .service-item {
    width: 95%;
    padding: 1.85rem 0.75rem;
  }

  .services-section .services h3 {
    font-size: 1.35em;
  }

  .services-section .services .service-item img {
    margin: 1.5rem 0;
    max-width: 100%;
  }
  .seo-section-wrap .sseo-exc-item {
    padding: 1rem 0;
  }
  .seo-section-wrap .sseo-exc-item:nth-child(2) .row,
  .seo-section-wrap .sseo-exc-item:nth-child(4) .row {
    flex-direction: column-reverse;
  }
  .seo-section-wrap .sseo-exc-img {
    margin: 1.5rem 0 2.5rem;
  }
  .seo-section-wrap .sseo-exc-img img {
    max-width: 100%;
  }
  .sseo-why-hire-us {
    padding: 2.5rem 0;
  }
  .sseo-why-hire-us h6 {
    max-width: 100%;
  }
  .sseo-why-hire-us .sseo-whu-item-wrap {
    margin-bottom: 1rem;
  }
  .sseo-why-hire-us .sseo-whu-item-wrap .sseo-whu-item h4 {
    font-size: 1.25rem;
  }
  .high-service-aid-wrap {
    padding: 2rem 0;
  }
  .high-service-aid-wrap h3 {
    font-size: 2rem;
    max-width: 100%;
    line-height: 1.35;
  }
  .seo-usp-wrap {
    padding: 0;
  }
  .seo-usp-wrap h3 {
    font-size: 1.75rem;
    line-height: 1.35;
    max-width: 100%;
  }
  .seo-usp-wrap .seousp-points-wrap {
    padding: 2.5rem 0 0;
  }
  .seo-usp-wrap .seo-usp-points {
    padding: 1rem 0;
  }
  .seo-section-wrap .seo-faq-wrap {
    padding: 1rem 0;
  }
  .seo-section-wrap .seo-faq-wrap h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
