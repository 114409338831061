/* Font Imports */
@font-face {
  font-family: "DMSans-Regular";
  src: url("../../fonts/DMSans-Regular.ttf");
}

@font-face {
  font-family: "DMSans-Italic";
  src: url("../../fonts/DMSans-Italic.ttf");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("../../fonts/DMSans-Medium.ttf");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("../../fonts/DMSans-Bold.ttf");
}

:root {
  /* Fonts */
  --font-regular: "DMSans-Regular";
  --font-italic: "DMSans-Italic";
  --font-medium: "DMSans-Medium";
  --font-bold: "DMSans-Bold";
  /* Colors */
  --green: #60bf58;
  --green-light: #deffdb;
  --green-light-1: rgba(210, 255, 241, 0.4);
  --green-1: #60bf58;
  --green-highlight: rgb(174, 245, 188);
  --blue: #2a81d8;
  --blue-light: #d5eaff;
  --black: #000;
  --black-30: #303030;
  --black-42: #424242;
  --dark-1: #222;
  --dark-2: #333;
  --dark-3: #555;
  --dark-4: #777;
  --dark-5: #616161;
  --dark-5: #999;
  --white: #fff;
  --light-1: #eee;
  --light-2: #fafafa;
  --light-3: #fdfdfd;
  --light-4: #aaa;
  --light-5: #e0e0e0;
  --highlight: #fd6584;
}

/* Resets */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DMSans-Regular", sans-serif;
}

*::selection {
  background-color: var(--green-highlight);
  color: var(--black);
}

*::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: var(--dark-3);
  border-radius: 1em;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3 {
  font-family: var(--font-bold);
}

h4,
h5 {
  font-family: var(--font-medium);
}

h6 {
  font-family: var(--font-regular);
  font-size: 1.04rem;
  line-height: 2;
}

p {
  font-family: inherit;
  font-size: 1.02rem;
  line-height: 1.8;
}

button:focus,
button:focus-within {
  box-shadow: none !important;
  outline: none;
}

.highlight {
  font-family: var(--font-bold);
  font-weight: 500;
}

.highlight-u {
  font-family: var(--font-bold);
  font-weight: 500;
  border-bottom: 3px solid var(--blue);
}

.form-control:focus,
.form-control:focus-within {
  box-shadow: none;
  border-color: var(--green);
}

textarea {
  resize: none;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #eee;
  margin: 1.5rem 0;
}

.cursor-pointer {
  cursor: pointer;
}
