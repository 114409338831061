/* Projects Page Styles */

.project-item-wrap {
  padding: 3.5em 0;
}

.project-item:not(:last-child) {
  margin: 4em 0;
  padding: 0 0 2em 0;
  border-bottom: 1px solid var(--light-1);
}

.project-content {
  padding: 0 1.75rem 0 0;
  text-align: justify;
}

.project-content h5 {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 1.15rem;
  margin: 0 0 1.25rem 0;
}

.project-content h6 {
  font-family: var(--font-italic);
  font-size: 1.025rem;
  color: var(--dark-3);
}

.project-content p {
  font-size: 0.95rem;
  color: var(--dark-3);
}

.project-image-wrap {
  width: 100%;
  height: 350px;
  padding: 1em 0;
  margin: 0 0 1em 0;
}

.carousel-item img {
  min-height: 100%;
  max-height: 350px;
}

.project-stat-wrap {
  padding: 1em 0;
}

.project-stat-wrap h6 {
  font-family: var(--font-medium);
  font-size: 0.95rem;
  margin: 0;
  line-height: 1.5;
}

.project-stat-wrap span {
  font-family: var(--font-medium);
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--dark-5);
}

.pst-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: var(--light-3); */
  /* padding: 1.5em 1.5em 0; */
  border-radius: 0.3em;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  padding: 2rem 0;
}

.pst-container .pst-item {
  padding: 0 2.75rem 0 0;
  margin-bottom: 1.5rem;
}

.pst-container .pst-item img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5em;
}

@media only screen and (max-width: 1600px) {
  .project-image-wrap {
    width: 100%;
    height: 250px;
    padding: 1em 0;
    margin: 0 0 1em 0;
  }
  .carousel-item img {
    min-height: 100%;
    max-height: 250px;
  }
}
