section.about-us {
  padding: 5em 1em 5em;
}

.about-us .image-holder {
  display: block;
  margin: 0 auto;
  width: auto;
}

.about-us .image-holder img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 2rem;
}

.about-us .tag {
  color: var(--green-1);
  text-transform: uppercase;
  font-family: var(--font-bold);
  font-size: 0.8em;
  letter-spacing: 0.125em;
}

.about-us p[data-em] {
  font-family: var(--font-medium);
}

@media only screen and (max-width: 768px) {
  section.about-us {
    padding: 7em 1em 3em;
  }
  .about-us .image-holder {
    padding: 1.2em;
  }
}
