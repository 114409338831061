@import url("../App.css");

/***** LANDING PAGE  *****/

/* Main Section */

.main-container {
  background-image: url("../../../images/HomeEl.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center -75%;
  /* background-color: var(--green-light); */
  /* clip-path: polygon(0 0, 100% 1%, 100% 98%, 0 90%); */
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%); */
}

.main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.main-section img {
  display: block;
  max-width: 90%;
  width: 42rem;
  margin: 0 auto;
}

.content h1 {
  font-size: 3.35em;
}

.content h1 span {
  font-family: var(--font-bold);
}

.content h4 {
  color: var(--dark-5);
  font-size: 1.1rem;
}

.content p {
  width: 70%;
  color: var(--dark-3);
}

.content .button-group {
  display: flex;
  gap: 15px;
  margin-top: 2em;
}

.content .button-group button {
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 1em;
  border: none;
  padding: 0.5em 1.2em;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
}

.content .button-group button[data-contact] {
  background-color: var(--green);
  color: var(--white);
}

.content .button-group button[data-more] {
  background-color: var(--blue);
  color: var(--white);
}

.content .button-group button img {
  width: 14px;
}

/* About us */

.about-section {
  padding: 5rem 0;
  background-color: var(--light-3);
}

.about-section p {
  font-size: 1.15em;
  line-height: 1.9;
}

.about-section .services {
  padding: 4em 0 2em;
}

.about-section .services .service-item {
  margin: 0;
  width: 95%;
}

.about-section .services .service-item:not(:last-child) {
  padding: 0 0 10em 0;
}

.about-section .services .service-item .service-img {
  width: 100%;
  height: 180px;
  min-height: 180px;
}

.about-section .services .service-item .service-img img {
  width: 27em;
  max-width: 100%;
  max-height: 100%;
}

.about-section .services .service-item h3 {
  font-size: 1.35em;
  margin: 2rem 0 0.5rem 0;
}

.about-section .services p {
  color: var(--dark-3);
}

.about-section .tag-group li {
  padding-bottom: 1em;
}

.view-services-btn {
  font-family: var(--font-bold);
  font-size: 0.9rem;
  display: block;
  margin: 2rem auto 0;
  border: 2px solid var(--black);
  background: none;
  color: var(--black);
  padding: 0.3rem 1.5rem;
}

.view-services-btn:hover {
  background-color: var(--black);
  color: var(--white);
  border: 2px solid var(--black);
}

.view-services-btn:focus {
  background-color: var(--black);
  box-shadow: none;
}

.findmorebtn {
  font-size: 0.9rem;
  padding: 0.3rem 1rem;
  background: var(--blue);
  color: var(--white);
  border: none;
}

.findmorebtn:hover,
.findmorebtn:focus {
  background: var(--green);
}

/* Our Stats */

.our-stats {
  margin: 0 0 4rem 0;
}

.ourstats-container {
  background-color: var(--white);
  border: 1px solid var(--light-1);
  border-radius: 0.25rem;
  padding: 3rem;
}

.ourstats-container h2,
.ourstats-container p {
  text-align: center;
}

.ourstats-container img {
  width: 15rem;
  max-width: 100%;
}

.stats-box-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.stat-box {
  background-color: var(--green-light);
  padding: 2rem 1.5rem;
  text-align: center;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0.85rem;
}

.stat-box h4 {
  font-family: var(--font-bold);
  font-size: 3.25rem;
}

.stat-box h6 {
  font-family: var(--font-medium);
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0;
}

#projectsBtn {
  border: none;
  background-color: var(--black);
  margin: 3rem 0 0;
  border-radius: 0.15rem;
  transition: 0.25s ease-in-out transform;
}

#projectsBtn img {
  width: 14px;
  margin-left: 0.75rem;
  transition: 0.2s ease-in-out all;
}

#projectsBtn:hover img {
  margin-left: 1rem;
}

#projectsBtn:focus {
  background-color: var(--black);
  box-shadow: none;
  border-radius: 0.15rem;
}

/* Our Businesses Logo */

.our-business {
  padding: 0.5rem 0 0;
}

.our-business .logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  margin: 1.5rem 0 0;
}

.our-business .logo-group .logo-item {
  width: 180px;
  max-width: 100%;
}

.our-business .logo-group img {
  width: 100%;
  max-width: 100%;
}

/* Why Choose Us */

.why-choose-container {
  background-color: var(--white);
}

.usp {
  padding: 3rem 0 2rem;
}

.usp .usp-points {
  width: 100%;
  margin: 0 auto;
}

.usp h5 {
  text-align: left;
  color: var(--dark-2);
  line-height: 1.45;
}

.usp h5 span {
  font-family: var(--font-bold);
  color: var(--green-1);
}

.usp p {
  color: var(--dark-3);
  margin: 1rem 0;
}

.usp .usp-points {
  padding: 2.5rem 0 0;
}

.usp .usp-points .usp-item {
  position: relative;
  width: 80%;
  margin: 0 auto 1.75rem auto;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
  max-width: 100%;
  min-height: 370px;
  max-height: 370px;
  height: 100%;
  background: var(--white);
  border-radius: 0.35rem;
  overflow: hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.35s ease-in-out;
}

.usp .usp-points .usp-item:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  transform: scale(1.06);
}

.usp .usp-points .usp-item .usp-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.usp .usp-points .usp-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 200px;
  height: 150px;
  margin-bottom: 2em;
  transition: all 0.3s ease-in-out;
}

.usp .usp-points .usp-img img {
  max-width: 100%;
  max-height: 100%;
  width: 250px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.usp .usp-points .usp-item h4 {
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.usp .usp-points .usp-item:hover h4 {
  transform: translateY(-185px);
}

.usp .usp-points .usp-item:hover img {
  width: 60px;
  margin: 0 auto;
  transform: translateY(-120px);
}

.usp .usp-points .usp-item p {
  width: 100%;
  position: absolute;
  left: 0;
  text-align: left;
  font-size: 0.88rem;
  line-height: 1.6;
  color: var(--white);
  transform: translateY(130%);
  transition: transform 0.25s ease-in-out;
  background-color: var(--dark-1);
  padding: 1rem;
  height: 70%;
}

.usp .usp-points .usp-item:hover p {
  transform: translateY(-57%);
}

/* Contact CTA */

.contact-cta {
  padding: 0 0 3.5rem;
  width: 100%;
  height: 100%;
}

.contact-cta .ccta-bg {
  background-image: url("../../../images/contact-cta.jpg");
  background-size: 100%;
  background-position: center 40%;
  background-repeat: no-repeat;
  padding: 5rem 6.5rem;
  border-radius: 5px;
}

.contact-cta .ccta-bg .contact-us-cta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: auto;
  background: var(--black);
  padding: 0.4rem 1.2rem;
  border: none;
}

.contact-cta .ccta-bg .contact-us-cta ion-icon {
  font-size: 1rem;
  color: var(--white);
}

.contact-cta .ccta-bg p {
  line-height: 1.65;
}

/* Slider Styles */

.slider-wrap {
  margin: 3rem 0 0;
}

.slider-wrap .owl-item .item img {
  width: 190px;
  max-width: 100%;
}

.slider-wrap .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-wrap .owl-carousel .owl-stage .owl-item {
  display: flex;
  justify-content: center;
}

/***** END OF LANDING PAGE  *****/

/* Media Queries */

@media only screen and (max-width: 768px) {
  p {
    line-height: 1.6;
    font-size: 0.97rem;
  }

  .main-section p,
  .about-section p {
    line-height: 1.6;
  }
  .main-section {
    padding: 7rem 1.25rem 5rem;
  }

  .main-section h1 {
    font-size: 2.15em;
  }

  .main-section h4 {
    font-size: 1.25em;
  }

  .main-section p {
    width: 100%;
    font-size: 0.9rem;
  }

  .main-section .image-holder {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 3.5rem 0 0;
  }

  .about-section {
    padding-inline: 0.6em;
    text-align: left;
  }

  .about-section .services {
    padding: 2em 0;
  }

  .about-section .services img {
    padding-bottom: 1.75em;
  }

  .about-section .services .service-item {
    width: 100%;
  }

  .about-section .services .service-item:not(:last-child) {
    padding-bottom: 5em;
  }

  .ourstats-container {
    padding: 2.5rem 1rem;
  }

  #projectsBtn {
    margin-top: 1.2rem;
  }

  .contact-cta .ccta-bg {
    padding: 2.5rem 1.2rem;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.65)
      ),
      url("../../../images/contact-cta.jpg");
    color: #fff;
  }

  .our-stats {
    margin-inline: 0.65rem;
  }

  .ourstats-container .stats-box-wrap {
    margin: 2.5rem 0;
    flex-direction: column;
  }

  .ourstats-container .stats-box-wrap .stat-box:nth-child(2) {
    margin-top: 0 !important;
  }

  .usp {
    padding-inline: 1em;
  }

  .usp .usp-points {
    padding: 0;
  }

  .testimonial-section .testimonial-content::after {
    content: "";
    display: none;
  }
}
