section.contact-us {
  padding: 5em 0.5em 5em;
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.97),
      rgba(255, 255, 255, 0.97)
    ),
    url("../../../images/Contact.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center 30%;
}

.contact-us .tag {
  color: var(--green-1);
  text-transform: uppercase;
  font-family: var(--font-bold);
  font-size: 0.8em;
  letter-spacing: 0.125em;
}

.contact-us .left-img {
  padding: 1rem;
}

.contact-us .contact-form {
  padding: 2em;
  margin: 2em 0 0;
  border-radius: 0.5em;
  background-color: rgba(253, 253, 253, 0.45);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.contact-us .contact-form .form-box {
  padding-bottom: 1.5em;
}

.contact-us .contact-form .form-box input {
  font-size: 1rem;
  color: var(--black);
  border: none;
  border-bottom: 2px solid var(--light-4);
  background: none;
  border-radius: 0;
  padding-inline: 0;
}

.contact-us .contact-form .form-box input::placeholder,
.contact-us .contact-form .form-box #msg_textarea::placeholder {
  color: var(--light-4);
}

.contact-us .contact-form .form-box input:focus,
.contact-us .contact-form .form-box #msg_textarea:focus {
  border-bottom: 2px solid var(--green-1);
}

.contact-us .contact-form .form-box #msg_textarea {
  font-size: 1rem;
  border: none;
  border-bottom: 2px solid var(--light-4);
  border-radius: 0;
  background: none;
  padding-inline: 0;
  margin: 0 0 1.25rem 0;
}

.contact-us .contact-us-btn {
  width: 100%;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background-color: var(--green-1);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: none;
  color: var(--white);
  margin: 1.5rem 0 0;
  border-radius: 0.25rem;
  transition: all 0.25s ease-out;
  text-transform: uppercase;
  letter-spacing: 0.25px;
}

.contact-us .contact-us-btn img {
  width: 16px;
  margin-left: 0.25rem;
  transition: 0.2s ease-in-out transform;
}

.contact-us .contact-us-btn:hover {
  background-color: var(--green-1);
}

.contact-us .contact-us-btn:hover img {
  transform: rotate(40deg) translate(5px, -5px);
}

.contact-us .contact-us-btn:active {
  transform: scale(0.95);
}

.contact-us .contact-info-wrap {
  margin: 0;
  padding: 0.25rem 2rem;
}

.contact-us .contact-info {
  background: none;
  padding: 0 1.65em 2em;
  margin: 2em 0 0;
  border-radius: 0.25em;
}

.contact-us .con-item {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.contact-us .con-item:not(:last-child) {
  position: relative;
  padding-bottom: 1em;
}

.contact-us .con-item img {
  opacity: 0.9;
  max-width: 25px;
}

.contact-us .con-det {
  color: var(--black);
  padding: 0 0 0.75em 0;
  border-radius: 0.35em;
}

.contact-us .con-det h5 {
  font-family: var(--font-bold);
  font-size: 0.8rem;
  color: var(--dark-3);
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.contact-us .con-det h6 {
  font-size: 1.19em;
  line-height: 1.6;
  color: var(--black);
}

.contact-us .con-det h6[data-blue] {
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--bs-primary);
  cursor: pointer;
}

.contact-us .con-det h6 a {
  color: var(--black);
}

.contact-us .map-wrap {
  margin: 3.5em 0 0;
}

/* Cpanel */

.text-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-btn-wrap .cpanel-btn button {
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 6px 15px;
  border-radius: 5px;
  background: #d1ffcd;
}

.text-btn-wrap .cpanel-btn button ion-icon {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  section.contact-us {
    padding: 4em 0.5em 3.5em;
  }
  .contact-us .contact-info-wrap {
    padding: 0;
  }
  .contact-us .contact-info {
    padding: 1.1em;
  }

  .contact-us .contact-info h5 {
    font-size: 0.8rem;
    line-height: 1.7;
  }
  .contact-us .con-det h6[data-blue] {
    font-size: 0.85rem;
    margin-top: 1rem;
  }

  .contact-us .contact-info h6 {
    font-size: 0.96em;
  }
  .text-btn-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}
