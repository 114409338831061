section.legal-section {
  padding: 7em 0 3em;
}

section.legal-section h2 {
  margin: 0;
}

section.legal-section p {
  font-size: 1.05em;
  line-height: 1.9;
}

section.legal-section span {
  font-size: 1.05em;
}

section.legal-section h5 {
  font-family: var(--font-bold);
  font-size: 1.25em;
}

section.legal-section ul {
  list-style: disc;
  padding: 0.5em 0 0 1.5em;
  margin-bottom: 1em;
}

section.legal-section ul li {
  padding-bottom: 0.35em;
  font-size: 1.05em;
}

section.legal-section ul li::first-letter {
  text-transform: capitalize;
}

@media only screen and (max-width: 768px) {
  section.legal-section {
    padding: 7em 1em 2em;
  }
}
