section.careers-section {
  padding: -5rem 0 4rem 0;
}

.careers-header {
  width: 100%;
  padding: 4rem 0 0 0;
}

.careers-header img {
  display: block;
  max-width: 100%;
  width: 17rem;
  margin: 2rem auto;
}

.careers-header h1 {
  position: relative;
  display: inline-block;
  font-family: var(--font-bold);
  color: var(--black);
  padding: 0 0 20px 0;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.careers-header h1::before {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: var(--green-1);
}

.careers-header h4 {
  font-family: var(--font-regular);
  font-size: 1.15rem;
  color: var(--black);
  line-height: 1.8;
  margin: 0 auto;
}

.view-det {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.job-lists {
  margin: 30px 0;
  padding: 0;
}

.job-lists h4 {
  font-family: var(--font-bold);
  color: var(--dark-2);
  font-size: 1.2rem;
  line-height: 1.2;
  padding: 0;
  margin: 0 0 7px 0;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.job-lists h5 {
  color: var(--dark-2);
  font-size: 1.15rem;
  line-height: 1.65;
  margin-top: 15px;
  margin: 0 auto 2rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

.job-details {
  display: none;
  margin: 10px 0 0;
}

.job-details.open {
  display: block;
}

#applyJobBtn {
  display: inline-block;
  font-family: var(--font-bold);
  font-size: 0.9rem;
  background-color: var(--green);
  border: none;
  padding: 0.35rem 1rem;
  color: var(--black);
  margin: 0;
  transition: 0.2s ease-in-out all;
}

#applyJobBtn.hideBtn {
  display: none;
}

#applyJobBtn:hover {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--green-1);
  color: var(--white);
}

#applyJobBtn img {
  margin-left: 8px;
}
/* 
#careersTab {
  width: 100%;
  height: 100%;
  padding: 0.75rem 0;
  background-color: var(--light-3);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.45rem;
  overflow: hidden;
}

#careersTab .nav-item {
  padding: 0;
  margin: 0 0 10px 0;
  cursor: pointer;
}

#careersTab .nav-link {
  font-size: 1.01rem;
  padding-inline: 1rem;
  color: var(--dark-4);
}

#careersTab .nav-link.tab-active {
  font-family: var(--font-medium);
  color: var(--dark-2);
  background-color: var(--white);
} */

.tab-dets {
  display: flex;
  gap: 1rem;
  margin: 0;
}

.tab-dets span {
  font-size: 0.8rem;
  color: var(--dark-4);
  margin-left: 5px;
  line-height: 1;
  text-transform: initial;
}

.careers-jobs-list-wrap {
  margin: 0;
}

.job-list .job-item {
  position: relative;
  padding: 1.45rem;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.45rem;
}

.job-list .job-item h6 {
  font-family: var(--font-medium);
  font-size: 1rem;
  margin: 0.5rem 0;
}

.job-inner-list {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
}

.job-inner-list li {
  padding: 0 0 0.7rem 0;
}

.job-inner-list li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 800;
  font-size: 12px;
  margin-right: 10px;
}

.apply-form {
  display: none;
  width: 50%;
  padding: 1rem 1.25rem;
  margin: 10px 0;
  border: 1px solid #eee;
  /* background-color: var(--light-3); */
  border-radius: 0.35rem;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.apply-form.show {
  display: block;
}

.apply-form .form-box {
  margin: 0 0 15px 0;
}

.apply-form .form-box label {
  font-family: var(--font-bold);
  color: var(--dark-4);
  display: block;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.35px;
}

.apply-form .form-box input {
  font-family: var(--font-medium);
  font-size: 0.8rem;
  color: var(--dark-1);
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  outline: none;
  padding: 0.35rem 0.6rem;
  margin: 0.35rem 0 0;
  width: 100%;
  background: none;
  transition: 0.15s ease-in-out all;
}

.apply-form .form-box input[type="file"] {
  padding: 0.6rem 1rem;
}

.apply-form .form-box input::placeholder {
  font-family: var(--font-regular);
  color: var(--dark-4);
}

.apply-form .form-box input:focus {
  border: 1px solid var(--green-1);
}

.job-dets {
  border-top: 1px solid #eee;
}

.arrowImg {
  transform: rotate(0);
  transition: all 0.25s ease-in-out;
}

.rotateArrow {
  transform: rotate(180deg);
}
